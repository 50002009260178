<template>
  <div>
    <json-viewer :value="jsonData"></json-viewer>
  </div>
</template>

<script>
import config from '@/config';

export default {
  name: 'Status',
  data() {
    return {
      jsonData: {
        name: 'dashboard',
        status: 'ok',
        environment: config.ENV,
        version: config.VERSION,
        commit: config.GIT_COMMIT,
        deployTime: config.DEPLOY_TIME
      }
    };
  },
  metaInfo: {
    title: 'Status'
  }
};
</script>
<style>
#app {
  text-align: left !important;
}
</style>
